<template>
  <div class="blog-post-list-item">
    <div class="blog-post-list-item-body">
      <h4>{{ post.title }}</h4>
      <div class="links-container">
        <p class="date">{{ post.date }}</p>
        <ul class="link-list">
          <li v-for="(tag, ti) of post.tags" :key="ti">
            <a class="text-primary" href="#">{{ tag }}</a>
          </li>
        </ul>
      </div>
      <div class="description" v-html="post.description" />
      <a href="#" class="cta">
        <span class="material-symbols-outlined arrow_right_alt">
          arrow_right_alt
        </span>

        Read More
      </a>
    </div>
    <div class="blog-post-list-item-img">
      <div
        class="img-container img-container-rounded"
        :style="`background-image: url('${post.image_url}')`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({
        title:
          "Enjoy the wonders of Spain and make friends for life in Madrid and Barcelona!",
        date: "25/03/2023",
        tags: ["Life in Spain", "Barcelona", "Madrid"],
        description: `<p>
          Preview of the text. Living and studying in Spain is an incredible
          experience filled with vibrant culture, rich history, and endless
          opportunities for growth. The lively streets of Barcelona, the
          historic charm of Madrid, and the picturesque beaches of Valencia make
          Spain a truly enchanting destination for students from all over the
          world. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Mollitia, perspiciatis expedita? Excepturi...
        </p>`,
        image_url: "/web-v2/img/header-16.png",
      }),
    },
  },
};
</script>
