<template>
  <div>
    <PageHeader fileName="header-21" withTablet withMobile>
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="py-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('header.title')" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("header.subtitle") }}
            </h6>
            <p>
              {{ $lang("header.desc") }}
            </p>
            <p class="fw-500">{{ $lang("header.topics_title") }}</p>

            <ul class="link-list justify-content-start">
              <li>
                <a href="#" class="text-primary">TEST</a>
              </li>
              <li>
                <a href="#" class="text-primary">TEST</a>
              </li>
              <li>
                <a href="#" class="text-primary">TEST</a>
              </li>
              <li>
                <a href="#" class="text-primary">TEST</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-7">
      <div class="container mb-5">
        <BlogPostItem />
        <BlogPostItem />
      </div>

      <div class="d-flex gap-4 align-items-center justify-content-center">
        <button
          class="btn btn-transparent text-primary gap-1 d-flex align-items-center"
        >
          <span class="material-symbols-outlined fs-4 m-0">
            arrow_left_alt
          </span>
          <span class="text-underlined">Previous</span>
        </button>
        <button
          class="btn btn-transparent text-primary gap-1 d-flex align-items-center"
        >
          <span class="text-underlined">Next</span>
          <span class="material-symbols-outlined fs-4 m-0">
            arrow_right_alt
          </span>
        </button>
      </div>
    </section>

    <ContactFormSection fileName="footer-18" theme="secondary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import BlogPostItem from "@/components/BlogPostItem.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    BlogPostItem,
  },
  data: () => ({}),
};
</script>

<style></style>
